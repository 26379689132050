.table-container {
  height: 500px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: rgb(0, 0, 0, 0);
    opacity: 0;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #dadbe2;
    margin-block-start: 3em;
    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background: #415473;

    border-radius: 40px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(#415473, 5);
  }

  .table-cover {
    overflow: hidden;
    border-radius: 20px;
    width: 98%;

    .article-asset-table {
      width: 100%;
      border-radius: 20px;
      border-collapse: collapse;

      .table-header {
        th {
          background-color: #415473;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 27px;
          text-align: center;
          color: #ffffff;
          border: 2px solid #072136;
          padding: 8px 0px;
        }
      }
      .table-body {
        td {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          background: rgba(218, 219, 226, 0.2);
          color: #415473;
          border: 2px solid #dadbe2;
          padding: 12px 0px;

          &:last-of-type {
            text-decoration: underline;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
