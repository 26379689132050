.PostBox {
  // max-width: 1030px;
  height: 564px;
  border: 1px solid #dadbe2;
  border-radius: 30px;

  &--edit {
    height: auto;
  }

  &__header {
    background: #f4f5f8;
    padding: 30px;
  }

  .PostBox__date {
    font-size: 32px;
    line-height: 48px;
  }
  .PostBox__time {
    font-size: 16px;
    line-height: 24px;
  }
  .PostBox__box {
    padding: 40px 20px 40px 40px;
  }

  .PostBox__content {
    max-height: 100%;
    overflow-y: auto;
    h1 {
      font-size: 32px;
      line-height: 1.2;
      
    }
    h2 {
      font-size: 22px;
      line-height: 28px;
      
    }

    h3 {
      font-size: 20px;
      line-height: 24px;
      
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin-top:0px;
      margin-bottom: 0px;
      
    }
  }

  .simplebar-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ql-container {
    flex-grow: 1;
  }
}
