.Login {
  background-color: #072136;
  color: #fff;
  padding-top: 50px;

  a {
    &:hover {
      color: darken(#fff, 5);
    }
  }

  &__card {
    background: #415473;
    box-shadow: 0px 0px 200px rgba(3, 12, 20, 0.6);
    border-radius: 30px;
    padding: 37px 16px;
    max-width: 716px;
    margin-top: 70px;
    margin-bottom: 70px;
  }

  &__title {
    font-size: 32px;
    line-height: 48px;
  }

  &__form {
    max-width: 484px;
  }

  &__btn {
    max-width: 143px;
  }
}
