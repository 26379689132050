.pf-frame {


    height: 298px;
    width: 290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .imageContainer {
        width: 100%;
        height: 250px;


        img {
            object-fit: cover;
            display: block;
            margin: auto;
        }
    }


    .description-box {
        height: 90px;
        width:100%;
        .description {

            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
        }

        color: #566575;
        padding:20px 13px;

    

    }

    &:hover {
        filter: drop-shadow(0px 4px 28px rgba(218, 219, 226, 0.4));
        background-color: #fff;

    }
}