.main-header{
    width:100%;
    height: 100px;
    box-shadow: 0px 4px 28px rgba(218, 219, 226, 0.6);
    display: flex;
    flex-direction:row-reverse;
    align-items: center;
    .mh-rightContent{
        display: flex;
        flex-direction:row;
        column-gap: 20px;
        align-items: center;
        margin-right: 20px;
        

        .mh-userName{
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            text-align: right;
            color: #072136;
            
        }
        .mh-profile{
            width:40px;
            height:40px;
            object-fit: contain;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-content: center;
            .profile{
                width:100%;
                border-radius: 50%;
            }
        
            .dropdown {
                position: relative;
                display: inline-block;
              }
              
              .dropdown-content {
                display: flex;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 80px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                padding: 12px 16px;
                z-index: 10;
              }
              
              .dropdown:hover .dropdown-content {
                display: block;
              }

        }
 
    }  
    
}