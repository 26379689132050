.p-setting-container{
    width: 100%;
    min-height: 100vh;
    width: 100%;
    padding: 90px;
    > .header {
        .page-heading{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 45px;
            line-height: 68px;

        }


    }

    > .ps-container{
        .title{
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;

            /* Dark Navy */

            color: #072136;

        }
        .setting-header{
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin:30px 0px;
            padding-bottom:10px;
            border-bottom:1px solid #F37845;
            color: #415473;
            
        }
        .general-info-container{
            margin-left:20px;
            margin:0px 0px 20px 20px;
            .item-container{

                .item-label{
                    margin:20px 0px;

                }
                .item-value{
                        margin-left: 10px;
                        color: #415473;

                        opacity: 0.5;
                }
                .item-image{
                    height: 200px;
                    width: 290px;
                  margin-left:10px;
                  
                 
                img{
                    width:100%;

                  }
                }
            }
        }
    }

}