.clock-container{
    display:flex;
    max-width: 217px;
    // width:217px;
    height:60px;
    font-weight:bold;
    justify-content:space-between;
    text-align:center;
    color: #415473;
  
    
    .hours,.minutes{
      flex-grow:4;
      display:flex;
      justify-content:center;
      align-items:center;
      font-size:28px;
      border:2px solid #DADBE2;
      border-radius:10px;
  
    }

    .input-hours, .input-minutes{
      color: #415473;
      font-size:28px;
      border:2px solid #DADBE2;
      border-radius:10px;
      width:65px;
      height:60px;
      text-align: center;
    }
    .seperator{
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      flex-grow:1;
      max-width: 20px;
      .dot{
        width:6px;
        height:6px;
        border-radius:50%;
        background-color:#415473;
        margin:0;
        margin:0px 5px;
  
      }
      .space{
        width:1px;
        height:4px;
        opacity:0;
      }
      
    }
    .am-pm{
      flex-grow:3;
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      margin-left: 10px;
        span{
          flex-basis:45%;
          width: 40px;
          margin-left:10px;
         
          &.am{
            background-color:#DADBE2;
            color:white;
            display:flex;
            justify-content:center;
            align-items:center;
            border-radius:10px;
          }
          
          &.pm{
            background-color:#415473;
            color:white;
            border-radius:10px;
            display:flex;
            justify-content:center;
            align-items:center;
  
          }
          }
     
        
    }
    
  }