.form-container {
  .configuration-form {
    width: 80% !important ;

    .section-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
    }

    .input-container {
      margin-bottom: 40px;
      .publication-modal-inputs {
        .Input__label {
          color: #072136;
        }

        .Input__input {
          color: #415473;
        }
      }
      & > a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #5eb5ca;
        cursor: pointer;
      }

      .advanced-setting-container {
        z-index: 10000;
        padding: 10px 0px 0pc 30px;
      }
      .getcity-input {
        background-color: transparent;
        border: 1px solid #dadbe2;
        border-radius: 10px;
        padding: 15px;
        max-width: 100px;
        max-height: 50px;
        color: #415473;
        outline: none;
        transition: border-color 0.25s;
        // min-width:calc(100% - 10px);
        min-width:100%;
        &--error {
          border: 1px solid #ee465a;
        }
      }
      .radio-group {
        margin-top: 20px;

        .radio-input {
          label {
            color: #415473;
          }
          input[type="radio"] {
            appearance: none;
            width: 20px;
            height: 20px;
            border: 1px solid #dadbe2;
            border-radius: 50%;
            margin: 0;
            margin-right: 15px;
            position: relative;
          }

          input[type="radio"]::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: #00000000;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          input[type="radio"]:checked::before {
            background-color: #415473;
          }
        }
      }
    }
    .test-connection-button-container {
      border: 3px solid #735141;
      border-radius: 18px;
      align-self: flex-start;
      width: 200px;
      padding: 8px;
      .btn-primary {
        width: 100%;
        height: 55px;
        margin: auto;
        padding: 0;
      }
    }
    // .gender-sport-button {
    //   width: 60px;
    //   height: 37px;
      
    //   margin: 5px 5px;
    //   /* p-small/regular */
    //   font-family: 'Inter';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 14px;
    //   line-height: 17px;
    //   /* identical to box height */
    //   display: inline-flex;
    //   align-self: flex-start;
    //   align-items: center;
    //   border-radius: 10px;
    //   background-color: #F4F5F8;
    //   color: #415473;
    // }
    .gender-sport-wrapper {
      flex-wrap: wrap;
      display: grid;
      padding-top: 10px;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      grid-gap: 5px;

      .gender-sport-button {
          align-items: center;
          text-align: center;
          background-color: #F4F5F8;
          padding: 5px 15px 5px 15px;
          margin-right: 10px;
          border-radius: 10px;
          font-family: 'Poppins';
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #415473;
          cursor: pointer;
      }

      .gender-sport-button:hover {
          background-color: #9edcec;
          // opacity: 0.15;
          color: #5EB5CA;
      }
  }
  }
}
