.MultiSelectDropdown {
    width: 100%;
    .filter-title{
        width: 198px;
        height: 27px;
        margin:0;
        margin-bottom:10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 27px;
        color: #072136;
    }
    .MultiSelectDropdown__input {
        display: flex;
        justify-content: space-between;
        min-width:100%;
        align-items: baseline;
        color: #415473;
        font-size: 16px;
        line-height: 24px;
        // border: 1px solid #DADBE2;
        border-radius: 10px;
        padding: 15px;
        cursor: pointer;
        border: 1px solid #dadbe2;


        .filter-placeholder{
            width: 100%;
            height: 27px;
           
            margin:0;
            font-size: 16px;
            line-height: 24px;
            opacity: 0.5;
            display: flex;
            align-items: center;
            color: #415473;
        }
    }

    .collapse-menu {
        display: none;
        width: 100%;
        border: 1px solid #9497AB;
        border-top: none;
        border-radius: 0px 0px 10px 10px;
        // padding: 10px;
        background: rgba(218, 219, 226, 0.564);
        padding:20px 25px;
        .clearSelect{
            width: 126px;
            height: 21px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            margin-right: 10px;
            text-align: right;
            text-decoration-line: underline;
            color: #9497AB;

        }
        .selectedItemsContainer{
            align-items: center;
            flex-wrap: wrap;
            row-gap: 10px;
            .selectedItem{
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                padding:5px 7px;   
                background: #415473;
                display: flex;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                margin-right:15px;
                border-radius: 30px;
            }
            margin-bottom: 20px;
            
        }
        
       

        .dropdown-search {
            position: relative;
            width: auto;
            background: #FFFFFF;
            border: 1px solid #DADBE2;
            border-radius: 70px;
            text-align: left;
            margin: 10px 0;
         

            input {
                width: 100%;
                height: 100%;
                border: none;
                background: transparent;
                padding: 11px 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #415473;
                outline: 0;
            }

            svg {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: #415473;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            max-height: 300px;
            overflow-y: auto;

            li {
                padding: 10px 10px 10px 0;
                display: flex;
                justify-content: space-between;
                color: #9497AB;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #DADBE2;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #415473;
                border-radius: 3px;
            }
        }
    }

    &.active {

        .MultiSelectDropdown__input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-color: #9497AB;
        }

        .collapse-menu {
            display: block;
        }
    }




}