.Sidebar {
  background-color: #072136;
  color: #fff;
  width: 18%;
  flex-shrink: 0;
  min-height: 100vh;
  padding: 0 30px;

  .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .categories-container{

      margin-top:150px;
      row-gap: 70px;
      width:100%;
    .category{

        .category-heading{
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
        .links-container{
          list-style-type:none;
          
          .links{
            color:white;
            display: block;
            
            opacity: 0.5;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-bottom:20px;
            &.active{
              opacity: 1;
              &::after {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 5px;
                margin-bottom: 1px;
                border-top: 5px solid transparent;
                border-left: 10px solid var(--bs-orange);
                border-bottom: 5px solid transparent;
            }
            }
          }
        }

    }
  }

}
