.np_frame{
    height: 298px;
    width:290px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgba(218, 219, 226, 0.5);
    cursor:pointer;
    .np_imageContainer{
        display: flex;
        width:100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        .np_plusIcon{
            display: block;
            widtH:60px;
            height:60px;
            color: #DADBE2;

        }

    }

    .np_description-box{
        height:90px;
        .np_description{
            
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            
            text-align: center;
            
            
        }
        color: #566575;
        padding:20px 13px; 
         
    }

    &:hover{
        filter: drop-shadow(0px 4px 28px rgba(218, 219, 226, 0.6));
    }

}