.filter-title{
    // position: absolute;
    // top: -40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    color: #072136;
}
.SelectDropdown {
    position: relative;
    width: 100%;
    border: 1px solid #dadbe2;
    border-radius: 10px;
    // padding: 0 15px;
    cursor: pointer;
    user-select: none;
    


    .SelectDropdown__input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 54px;
        padding: 0 15px;
        width: 100%;
        color: #415473;
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #415473;
            opacity: 0.5;
        }
    }

    .collapse-menu {
        display: none;
        width: 100%;
        background: #FFFFFF;
        border-bottom-left-radius:10px; 
        border-bottom-right-radius: 10px;
        border-top: 1px solid #9497AB;

        ul {
            list-style: none;
            max-height: 300px;
            overflow-y: auto;
            padding: 0;
            margin: 0;
            li {
                padding: 10px 20px;
            }
            li:hover {
                background-color: #e0e0e0; /* Change this to the desired hover color */
              }
              &::-webkit-scrollbar {
                width: 5px;
                background-color: #DADBE2;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 3px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #415473;
                border-radius: 3px;
            }
        }
    }

    &.active {
        border: 1px solid #9497AB;
        .collapse-menu {
            display: block;
        }
    }
}