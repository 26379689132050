*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

// svg {
//   shape-rendering: geometricPrecision;
// }

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  width: 100%;
  display: block;
  // image-rendering: optimizeSpeed;
  // image-rendering: -moz-crisp-edges;
  // image-rendering: -o-crisp-edges;
  // image-rendering: -webkit-optimize-contrast;
  // image-rendering: optimize-contrast;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
