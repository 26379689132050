.Card {
    background: #ffffff;
    border-radius: 30px;
    padding: 35px 40px 75px;
    font-size: 16px;
    line-height: 24px;
    color: #072136;

    .Card__title {
        font-weight: 700;
        font-size: 28px;
        line-height: 37px;
        color: #072136;
        margin: 0;
        margin-bottom: 30px;
    }

    ol {
        padding-left: 1.7rem;
    }
}

@media (max-width: 991px) {
    // reduce card padding
    .Card {
        padding: 35px 30px 45px;
    }
}