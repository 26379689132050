.configuration-container {
    width: 100px;
    min-height: 100vh;
    width: 100%;
    padding: 40px 90px;
    .configuration-header {
        .page-heading{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 45px;
            line-height: 68px;

        }

        .edit-bar{
     
            span{
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
            .alert-span{
                color:var(--bs-pink);
            }
            .archive-span{
            }

        }


    }

    .configuration-panel-head{
        .cp-h2-heading{
            font-family: 'Poppins';
            font-style: normal;
            font-weight:700;
            color: #072136;
            font-size: 32px;
            line-height: 48px;
            
            /* Dark Navy */
            
            color: #072136;
            
        }
        .cp-h3-heading{
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            color: #070d36;
        }

    }
}



.configuration-panel {
    width: 100% !important;
    max-width: 70% !important;

    .form-container {
        

        .configuration-form {

            .section-title {
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
            }

            .input-container {
                margin-bottom: 40px;

                .input-container-label{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    color: #072136;
                    

                }

                .input-container-value{
                    padding-left:10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;

                    /* med blue */

                    color: #415473;
                    .red-alert{
                        color:#EE465A
                    }

                    .list-container{
                        list-style-type: none;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #415473;
                        padding-left: 0;

    
                    }
                    p{
                        margin:0;
                    }
    
                    
                }



                


                .publication-modal-inputs {

                    .Input__label {
                        color: #072136;

                    }

                    .Input__input {


                        color: #415473;



                    }

                }


                
                    &>a{

                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        text-decoration-line: underline;
                        color: #5EB5CA;
                        cursor:pointer;
                    }

                    .advanced-setting-container {
                        z-index: 10000;
                        padding:10px 0px 0pc 30px;
                    }

                    
                   .radio-group{
                    margin: 0px 20px;
                    
                    .radio-input{
                        label{
                            color:#415473;
                        }
                        input[type="radio"] {
                            appearance: none;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #DADBE2;
                            border-radius: 50%;
                            margin: 0;
                            margin-right: 15px;
                            position: relative;
                          }
                          
                          input[type="radio"]::before {
                            content: "";
                            display: block;
                            width: 6px;
                            height: 6px;
                            background-color: #00000000;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                          }
                          
                          input[type="radio"]:checked::before {
                            background-color: #415473;
                          }
                          }

                   } 
                    
                    
                }
                .test-connection-button-container{
                    border: 3px solid #415473;
                    border-radius:18px;
                   align-self: flex-start;
                   width:200px;
                   padding:8px;  
                   .btn-primary{
                    width:100%;
                    height:55px;
                    margin:auto;
                    padding:0;
                   }
             
                }

        }
    }

}



.confirmation-modal{


        height: 373px;
        width: 710px;



}