.Footer {
  color: #fff;
  background-color: #415473;
  padding: 24px 40px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .leftContainer{
    .logoImg{
      width: 150px;
      height: 30px;
    }
  }
  .rightContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
