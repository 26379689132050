.d-outlet-publishing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100px;
  min-height: 100vh;
  width: 100%;
  padding: 90px;
}

.d-outlet-publishing .overlap-wrapper {
  background-color: #ffffff;
  height: 100%;
  min-height: 2300px;
  overflow: hidden;
  width: 100%;
}

.d-outlet-publishing .overlap {
  height: 2736px;
  position: relative;
  top: -1px;
}

.d-outlet-publishing .overlap-2 {
  height: 2736px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.d-outlet-publishing .nav {
  left: 0 !important;
  position: absolute !important;
  top: 1px !important;
}

.d-outlet-publishing .text-wrapper-12 {
  color: var(--dark-navy);
  font-family: var(--p-large-bold-font-family);
  font-size: var(--p-large-bold-font-size);
  font-style: var(--p-large-bold-font-style);
  font-weight: var(--p-large-bold-font-weight);
  left: 0;
  letter-spacing: var(--p-large-bold-letter-spacing);
  line-height: var(--p-large-bold-line-height);
  position: absolute;
  text-align: right;
  top: 0;
  width: 176px;
}

.d-outlet-publishing .nav-desktop-side-instance {
  height: 2735px !important;
  left: 0 !important;
  position: absolute !important;
  top: 1px !important;
}

.d-outlet-publishing .design-component-instance-node {
  height: 28px !important;
  margin-bottom: -877px !important;
  position: relative !important;
  width: 178px !important;
}

.d-outlet-publishing .nav-desktop-side-2 {
  margin-bottom: -1426px !important;
}

.d-outlet-publishing .nav-desktop-side-3 {
  opacity: unset !important;
}

.d-outlet-publishing .component {
  left: 0 !important;
  position: absolute !important;
  top: 2230px !important;
}

.d-outlet-publishing .text-wrapper-13 {
  color: var(--dark-navy);
  font-family: "Poppins", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 374px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 218px;
  width: 100%;
  padding-top: 20px;
  margin-top: 20px;
}

.d-outlet-publishing .text-wrapper-14 {
  color: #f37845;
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 374px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 185px;
  width: 100%;
}

.d-outlet-publishing .date-items {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 1683px;
  left: 361px;
  position: absolute;
  top: 305px;
  width: 805px;
}

.d-outlet-publishing .date-item {
  align-items: center;
  background-color: #0000000a;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  height: 188px;
  padding: 20px 25px;
  position: relative;
  width: 724px;
}

.d-outlet-publishing .frame-7 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 172px;
  margin-bottom: -13.5px;
  margin-top: -13.5px;
  position: relative;
}

.d-outlet-publishing .frame-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.d-outlet-publishing .date-jul {
  color: var(--medium-blue);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  width: fit-content;
}

.d-outlet-publishing .text-wrapper-15 {
  font-weight: 700;
}

.d-outlet-publishing .text-wrapper-16 {
  color: #415473;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
}

.d-outlet-publishing .frame-9 {
  flex: 0 0 auto;
  position: relative;
}

.d-outlet-publishing .frame-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.d-outlet-publishing .text-wrapper-17 {
  align-self: stretch;
  color: #072136;
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -8px;
  position: relative;
}

.d-outlet-publishing .publishing-time {
  align-self: stretch;
  color: #072136;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  position: relative;
}

.d-outlet-publishing .text-wrapper-18 {
  color: #072136;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
}

.d-outlet-publishing .button-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  margin-top: -7px;
}

.d-outlet-publishing .button-instance {
  align-items: center !important;
  flex: 0 0 auto !important;
}

.d-outlet-publishing .download-button {
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  border: none;
  text-align: center;
  background-color: #f37845;
}

.d-outlet-publishing .content-icon {
  height: 188.7px !important;
  margin-bottom: -21.85px !important;
  margin-top: -21.85px !important;
  position: relative !important;
  width: 188.7px !important;
}

.dashboard_no_result {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 700;
  color: #415473;
}

.react-pagination {
  display: flex;
  gap: 15px;
  list-style: none;
  justify-content: center;

  .next {
      a {
          display: inline-block;
          border-radius: 5px;
          line-height: 40px;
          min-width: 40px;
          text-align: center;
          border: 1px solid #dadbe2;
          background-color: white;
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 700;
          color: #9497ab !important;
          text-decoration: none;
      }

      &.selected,
      &:hover:not(.disabled) {
          a {
              color: #fff !important;
              background: #5c89d1;
          }
      }

      &.disabled a {
          opacity: 0.7;
          cursor: alias;
      }
  }

  .disable {
      a {
          display: inline-block;
          border-radius: 5px;
          line-height: 40px;
          min-width: 40px;
          text-align: center;
          border: 1px solid #dadbe2;
          background-color: #dadbe2;
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 700;
          color: white !important;
          text-decoration: none;
      }

      &.selected,
      &:hover:not(.disabled) {
          a {
              color: white !important;
              background-color: #dadbe2;
          }
      }
  }

  li {
      padding: 0;

      a {
          display: inline-block;
          border-radius: 5px;
          line-height: 40px;
          min-width: 40px;
          text-align: center;
          // border: 1px solid #415473;
          font-family: 'Poppins';
          font-size: 14px;
          font-weight: 700;
          color: #9497ab !important;
      }

      &.selected,
      &:hover:not(.disabled) {
          a {
              color: #fff !important;
              background: #5c89d1;
          }
      }

      &.disabled a {
          opacity: 0.7;
          cursor: alias;
      }
  }
}