.admin-dashboard {
  min-height: 100vh;
  width: 100%;
  padding: 40px 90px;
  overflow: scroll;

  .dashboard-header {
    div {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
    }

    button {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      text-align: center;

      &:hover {
        color: white;
      }
    }
    .overview-bold {
      font-weight: 600;
    }
  }

  .client-name-heading {
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
    color: #072136;
    //margin-top: 80px;
  }

  .text-publication {
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: #072136;
  }
  .content-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns per row */
    gap: 50px;
    // display: grid;
    // grid-template-columns: auto auto auto;
    // justify-content: space-between;
    // column-gap: 50px;
    // row-gap: 70px;
  }
  .ng-info {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }
}

.modal-content {
  padding: 0px 40px 40px 40px;
}
.clientModal {
     .clientModalInput {
    .client-modal-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #415473;
    }

    .Input__container {
      width: 80%;
      margin: auto;
    }
  }
}
.publicationForm {
  .subtitle {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
  }

  .image-placeholder {
    .placeholder-container {
      height: 214px;
      width: 290px;
      background-color: rgba(213, 255, 5, 0.223);
    }
  }
}

